.isotope-box-x {
    position: relative;
    width: 100%;
}

.isotope-item-x {
    float: left;
    position: relative;
    height: 250px;
    width: 48%;
    margin: 1%;
    border-bottom: 2px solid rgba(250,250,250,0.1);
    padding-bottom: 30px;
    margin-bottom: 5%;
}

.isotope-item-x img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

figure.snip1321-x {
    overflow: hidden;
    text-align: center;
    -webkit-perspective: 50em;
    perspective: 50em;
  }
  figure.snip1321-x * {
    -webkit-box-sizing: padding-box;
    box-sizing: padding-box;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
  figure.snip1321-x img {
    width: 100%;
    height: 100%;
  }
  figure.snip1321-x figcaption {
    top: 50%;
    left: 20px;
    right: 20px;
    position: absolute;
    opacity: 0;
    z-index: 1;
  }
  figure.snip1321-x h4 {
      margin-top: 15px;
    margin-bottom: 5px;
    color: #232323;
  }
  figure.snip1321-x span {
      font-size: 14px;
      font-style: italic;
      display: block;
      color: #7a7a7a;
  }
  figure.snip1321-x i {
    position: relative!important;
    font-size: 18px;
    background-color: #a43f49;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
    color: #fff;
  }
  figure.snip1321-x:after {
    background-color: #ffffff;
    position: absolute;
    content: "";
    display: block;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 0;
  }
  figure.snip1321-x a {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  figure.snip1321-x:hover figcaption,
  figure.snip1321-x.hover figcaption {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  figure.snip1321-x:hover:after,
  figure.snip1321-x.hover:after {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 0.9;
  }
.hoverButton:hover {
    color:var(--hover-color) !important;
    opacity:var(--hover-opacity);
   }
@media screen and (max-width: 845px) {        
    .isotope-item-x {
        width: 94%;
        margin: 3%;
    }
    .isotope-item-x {
        height: 250px;
        border-bottom: none;
        text-align: center;
        margin-bottom: 5%;
    }
}